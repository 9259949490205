// node modules
import React from 'react'
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// sections
import Hero from 'components/pages/homepage/hero'
import Breadcrumbs from 'components/shared/breadcrumbs'
import AccessoriesBrowser from 'components/pages/accessories-browser'

import media from 'styles/media'

// types
import type { HeroSlide } from 'types/homepage'

const PageBreadcrumbs = styled.div`
  ${media.md.min} {
    margin: -50px 0 0 0;
  }
`

const AccessoriesPage: React.FC<PageProps<Queries.AccessoriesPageQuery>> = ({
  data,
}) => {
  const PAGE = data.AccessoriesPage?.PageAccessories!
  const PAGE_SEO = data.AccessoriesPage?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const HERO_SLIDES: HeroSlide[] =
    PAGE?.pageAccessoriesHero
      ?.filter((slide) => slide?.pageAccessoriesHeroImg)
      ?.map((slide) => ({
        image: {
          src: slide?.pageAccessoriesHeroImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: slide?.pageAccessoriesHeroImg?.altText!,
        },
        objPosX: slide?.pageAccessoriesHeroImgObjectPositionX!,
        heading: slide?.pageAccessoriesHeroTitle!,
        buttonText: slide?.pageAccessoriesHeroButton!,
        buttonLink: slide?.pageAccessoriesHeroUrl!,
      })) || []

  const POPUP = {
    image: {
      src: PAGE?.popupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.popupImg?.altText ?? '',
    },
    delay: PAGE?.popupDelay,
    link: PAGE?.popupLink ?? null,
    visibleOnMobile: PAGE?.popupVisibleOnMobile ?? false,
  }

  const PRODUCTS =
    data.allWpCfmotoAccessory.nodes.map((product) => ({
      name: product.title!,
      category: product.cfmotoAccessory?.accessoryInfoCategory!,
      link: `/akcesoria/${product.slug}`,
      priceText: product.cfmotoAccessory?.accessoryInfoPriceText!,
      image: {
        src: product?.cfmotoAccessory?.accessoryInfoColorsVariants?.[0]
          ?.accessoryInfoColorVariantsGallery?.[0]
          ?.accessoryInfoColorVariantsGalleryImage?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: product?.cfmotoAccessory?.accessoryInfoColorsVariants?.[0]
          ?.accessoryInfoColorVariantsGallery?.[0]
          ?.accessoryInfoColorVariantsGalleryImage?.altText!,
      },
      publishedDate: product.date!,
    })) || []

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Hero slides={HERO_SLIDES} page="akcesoria" />
      <PageBreadcrumbs>
        <Breadcrumbs crumbs={[{ name: data.AccessoriesPage?.title! }]} />
      </PageBreadcrumbs>
      <AccessoriesBrowser products={PRODUCTS} />
    </Layout>
  )
}

export default AccessoriesPage

export const query = graphql`
  query AccessoriesPage {
    AccessoriesPage: wpPage(slug: { regex: "/akcesoria/" }) {
      seo {
        ...WpSEO
      }
      title
      PageAccessories {
        pageAccessoriesHero {
          pageAccessoriesHeroUrl
          pageAccessoriesHeroButton
          pageAccessoriesHeroTitle
          pageAccessoriesHeroImgObjectPositionX
          pageAccessoriesHeroImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: DOMINANT_COLOR
                  breakpoints: [1280, 1920]
                )
              }
            }
          }
        }
        popupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        popupDelay
        popupLink
        popupVisibleOnMobile
      }
    }
    allWpCfmotoAccessory {
      nodes {
        id
        title
        slug
        date
        cfmotoAccessory {
          accessoryInfoCategory
          accessoryInfoPriceText
          accessoryInfoColorsVariants {
            accessoryInfoColorVariantsGallery {
              accessoryInfoColorVariantsGalleryImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
